import React, { useEffect } from "react";
import { setPusherClient } from "react-pusher";
import Pusher from "pusher-js";

const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  forceTLS: true,
});

export const SocketContextProvider = ({ children }) => {
  useEffect(() => {
    setPusherClient(pusherClient);
  }, []);

  return <>{children}</>;
};
