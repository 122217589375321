import axios from "axios";

/**
 * Sends a GraphQL mutation using Axios.
 *
 * @param {string} mutation - The GraphQL mutation as a string.
 * @param {string} authToken - The authorization token.
 * @returns {Promise} - A promise that resolves with the response of the GraphQL request.
 */
export function sendGraphql(mutation, authToken, variables = {}) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`, // Use bearer token for authorization
  };

  return axios({
    // url: "https://aymolive.testdev/graphql",
    url: "https://aymolive.com/graphql",
    method: "post",
    headers: headers,
    data: {
      query: mutation,
      variables,
    },
  });
}
