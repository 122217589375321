import {
  Constants,
  createCameraVideoTrack,
  useMeeting,
} from "@videosdk.live/react-sdk";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import liveHLS from "../../static/animations/live-hls.json";
import MicOnIcon from "../../icons/Bottombar/MicOnIcon";
import MicOffIcon from "../../icons/Bottombar/MicOffIcon";
import WebcamOnIcon from "../../icons/Bottombar/WebcamOnIcon";
import WebcamOffIcon from "../../icons/Bottombar/WebcamOffIcon";
import ScreenShareIcon from "../../icons/Bottombar/ScreenShareIcon";
import ChatIcon from "../../icons/Bottombar/ChatIcon";
import { OutlinedButton } from "../../components/buttons/OutlinedButton";
import { createPopper } from "@popperjs/core";
import useIsTab from "../../hooks/useIsTab";
import useIsMobile from "../../hooks/useIsMobile";
import { MobileIconButton } from "../../components/buttons/MobileIconButton";
import useIsHls from "../../hooks/useIsHls";
import LiveIcon from "../../icons/LiveIcon";
import LiveIconRed from "../../icons/LiveIconRed";
import { sideBarModes } from "../../utils/common";
import { Dialog, Popover, Transition } from "@headlessui/react";
import { useMeetingAppContext } from "../../MeetingAppContextDef";
import { sendGraphql } from "../../utils/graphql";

export function ILSBottomBar({
  bottomBarHeight,
  setIsMeetingLeft,
  selectWebcamDeviceId,
  setSelectWebcamDeviceId,
  selectMicDeviceId,
  setSelectMicDeviceId,
  meetingMode,
  stream,
}) {
  const [hlsState, setHlsState] = useState("HLS_STOPPED");
  const [isLive, setIsLive] = useState(false);

  const { sideBarMode, setSideBarMode } = useMeetingAppContext();

  const MicBTN = () => {
    const mMeeting = useMeeting();
    const [mics, setMics] = useState([]);
    const localMicOn = mMeeting?.localMicOn;
    const changeMic = mMeeting?.changeMic;

    const getMics = async (mGetMics) => {
      const mics = await mGetMics();

      mics && mics?.length && setMics(mics);
    };

    const [tooltipShow, setTooltipShow] = useState(false);
    const btnRef = useRef();
    const tooltipRef = useRef();

    const openTooltip = () => {
      createPopper(btnRef.current, tooltipRef.current, {
        placement: "top",
      });
      setTooltipShow(true);
    };
    const closeTooltip = () => {
      setTooltipShow(false);
    };

    return (
      <>
        <OutlinedButton
          Icon={localMicOn ? MicOnIcon : MicOffIcon}
          onClick={() => {
            mMeeting.toggleMic();
          }}
          bgColor={localMicOn ? "bg-gray-750" : "bg-white"}
          borderColor={localMicOn && "#ffffff33"}
          isFocused={localMicOn}
          focusIconColor={localMicOn && "white"}
          // tooltip={"Toggle Mic"}
          renderRightComponent={() => {
            return (
              <>
                <Popover className="relative">
                  {({ close }) => (
                    <>
                      <Popover.Button className="flex items-center justify-center mt-1 mr-1">
                        <div
                          ref={btnRef}
                          // onMouseEnter={openTooltip}
                          // onMouseLeave={closeTooltip}
                        >
                          <button
                            onClick={(e) => {
                              getMics(mMeeting.getMics);
                            }}
                          >
                            <ChevronDownIcon
                              className="w-4 h-4"
                              style={{
                                color: mMeeting.localMicOn ? "white" : "black",
                              }}
                            />
                          </button>
                        </div>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-10 px-4 pb-4 mt-3 transform -translate-x-1/2 left-1/2 bottom-full w-72 sm:px-0">
                          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className={" bg-gray-750 py-1"}>
                              <div>
                                <div className="flex items-center p-3 pb-0">
                                  <p className="ml-3 text-sm text-gray-900">
                                    {"MICROPHONE"}
                                  </p>
                                </div>
                                <div className="flex flex-col">
                                  {mics.map(({ deviceId, label }, index) => (
                                    <div
                                      key={`mics_${deviceId}`}
                                      className={`px-3 py-1 my-1 pl-6 text-white text-left ${
                                        deviceId === selectMicDeviceId &&
                                        "bg-gray-150"
                                      }`}
                                    >
                                      <button
                                        className={`flex flex-1 w-full ${
                                          deviceId === selectMicDeviceId &&
                                          "bg-gray-150"
                                        }`}
                                        key={`mics_${deviceId}`}
                                        onClick={() => {
                                          setSelectMicDeviceId(deviceId);
                                          changeMic(deviceId);
                                          close();
                                        }}
                                      >
                                        {label || `Mic ${index + 1}`}
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                {/* <div
                  style={{ zIndex: 999 }}
                  className={`${
                    tooltipShow ? "" : "hidden"
                  } overflow-hidden flex flex-col items-center justify-center pb-4`}
                  ref={tooltipRef}
                >
                  <div className={"rounded-md p-1.5 bg-black "}>
                    <p className="text-base text-white ">
                      {"Change microphone"}
                    </p>
                  </div>
                </div> */}
              </>
            );
          }}
        />
      </>
    );
  };

  const WebCamBTN = () => {
    const mMeeting = useMeeting();
    const [webcams, setWebcams] = useState([]);

    const localWebcamOn = mMeeting?.localWebcamOn;
    const changeWebcam = mMeeting?.changeWebcam;

    const getWebcams = async (mGetWebcams) => {
      const webcams = await mGetWebcams();

      webcams && webcams?.length && setWebcams(webcams);
    };

    const [tooltipShow, setTooltipShow] = useState(false);
    const btnRef = useRef();
    const tooltipRef = useRef();

    const openTooltip = () => {
      createPopper(btnRef.current, tooltipRef.current, {
        placement: "top",
      });
      setTooltipShow(true);
    };
    const closeTooltip = () => {
      setTooltipShow(false);
    };

    return (
      <>
        <OutlinedButton
          Icon={localWebcamOn ? WebcamOnIcon : WebcamOffIcon}
          onClick={async () => {
            let track;
            if (!localWebcamOn) {
              track = await createCameraVideoTrack({
                optimizationMode: "motion",
                encoderConfig: "h540p_w960p",
                facingMode: "environment",
                multiStream: false,
                cameraId: selectWebcamDeviceId,
              });
            }
            mMeeting.toggleWebcam(track);
          }}
          bgColor={localWebcamOn ? "bg-gray-750" : "bg-white"}
          borderColor={localWebcamOn && "#ffffff33"}
          isFocused={localWebcamOn}
          focusIconColor={localWebcamOn && "white"}
          // tooltip={"Toggle Webcam"}
          renderRightComponent={() => {
            return (
              <>
                <Popover className="relative">
                  {({ close }) => (
                    <>
                      <Popover.Button className="flex items-center justify-center mt-1 mr-1">
                        <div
                          ref={btnRef}
                          onMouseEnter={openTooltip}
                          onMouseLeave={closeTooltip}
                        >
                          <button
                            onClick={(e) => {
                              getWebcams(mMeeting?.getWebcams);
                            }}
                          >
                            <ChevronDownIcon
                              className="w-4 h-4"
                              style={{
                                color: localWebcamOn ? "white" : "black",
                              }}
                            />
                          </button>
                        </div>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-10 px-4 pb-4 mt-3 transform -translate-x-1/2 left-1/2 bottom-full w-72 sm:px-0">
                          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className={" bg-gray-750 py-1"}>
                              <div>
                                <div className="flex items-center p-3 pb-0">
                                  <p className="ml-3 text-sm text-gray-900">
                                    {"WEBCAM"}
                                  </p>
                                </div>
                                <div className="flex flex-col">
                                  {webcams.map(({ deviceId, label }, index) => (
                                    <div
                                      key={`output_webcams_${deviceId}`}
                                      className={`px-3 py-1 my-1 pl-6 text-white text-left ${
                                        deviceId === selectWebcamDeviceId &&
                                        "bg-gray-150"
                                      }`}
                                    >
                                      <button
                                        className={`flex flex-1 w-full ${
                                          deviceId === selectWebcamDeviceId &&
                                          "bg-gray-150"
                                        }`}
                                        key={`output_webcams_${deviceId}`}
                                        onClick={async () => {
                                          setSelectWebcamDeviceId(deviceId);
                                          const track =
                                            await createCameraVideoTrack({
                                              optimizationMode: "motion",
                                              encoderConfig: "h540p_w960p",
                                              facingMode: "environment",
                                              multiStream: false,
                                              cameraId: deviceId,
                                            });
                                          changeWebcam(track);
                                          close();
                                        }}
                                      >
                                        {label || `Webcam ${index + 1}`}
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                {/* <div
                  style={{ zIndex: 999 }}
                  className={`${
                    tooltipShow ? "" : "hidden"
                  } overflow-hidden flex flex-col items-center justify-center pb-4`}
                  ref={tooltipRef}
                >
                  <div className={"rounded-md p-1.5 bg-black "}>
                    <p className="text-base text-white ">{"Change webcam"}</p>
                  </div>
                </div> */}
              </>
            );
          }}
        />
      </>
    );
  };

  const ScreenShareBTN = ({ isMobile, isTab }) => {
    const { localScreenShareOn, toggleScreenShare, presenterId } = useMeeting();

    return isMobile || isTab ? (
      <MobileIconButton
        id="screen-share-btn"
        tooltipTitle={
          presenterId
            ? localScreenShareOn
              ? "Stop Presenting"
              : null
            : "Present Screen"
        }
        buttonText={
          presenterId
            ? localScreenShareOn
              ? "Stop Presenting"
              : null
            : "Present Screen"
        }
        isFocused={localScreenShareOn}
        Icon={ScreenShareIcon}
        onClick={() => {
          toggleScreenShare();
        }}
        disabled={
          presenterId
            ? localScreenShareOn
              ? false
              : true
            : isMobile
            ? true
            : false
        }
      />
    ) : (
      <OutlinedButton
        Icon={ScreenShareIcon}
        onClick={() => {
          toggleScreenShare();
        }}
        isFocused={localScreenShareOn}
        tooltip={
          presenterId
            ? localScreenShareOn
              ? "Stop Presenting"
              : null
            : "Present Screen"
        }
        disabled={presenterId ? (localScreenShareOn ? false : true) : false}
      />
    );
  };

  const LeaveBTN = () => {
    const { leave } = useMeeting();

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: liveHLS,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      height: 64,
      width: 170,
    };

    const _handleStartLiveStream = async () => {
      setHlsState("HLS_STARTING");
      const params = new URLSearchParams(window.location.search);
      const authToken = params.get("t");

      const response = await sendGraphql(
        `mutation StartBrowserBroadcast($slug: String) { startBrowserBroadcast(streamSlug: $slug) }`,
        authToken,
        { slug: stream.slug }
      );

      if (!response.data.data.startBrowserBroadcast) {
        alert("Unable to start live stream");
        return;
      }
      setHlsState("HLS_STARTED");
    };

    const _handleStopLiveStream = async () => {
      setHlsState("HLS_STOPPING");
      const params = new URLSearchParams(window.location.search);
      const authToken = params.get("t");

      const response = await sendGraphql(
        `mutation StopBrowserBroadcast($slug: String) { stopBrowserBroadcast(streamSlug: $slug) }`,
        authToken,
        { slug: stream.slug }
      );

      if (!response.data.data.stopBrowserBroadcast) {
        alert("Unable to stop live stream");
        return;
      }
      setHlsState("HLS_STOPPED");
      leave();
      setIsMeetingLeft(true);
    };

    const _handleClick = async () => {
      if (
        hlsState === Constants.hlsEvents.HLS_STARTED ||
        hlsState === Constants.hlsEvents.HLS_PLAYABLE
      ) {
        await _handleStopLiveStream();
      } else {
        await _handleStartLiveStream();
      }
    };

    return (
      <OutlinedButton
        Icon={
          hlsState === Constants.hlsEvents.HLS_STARTED ||
          hlsState === Constants.hlsEvents.HLS_PLAYABLE ||
          isLive
            ? LiveIconRed
            : LiveIcon
        }
        onClick={_handleClick}
        tooltipTitle={
          hlsState === Constants.hlsEvents.HLS_STARTED ||
          hlsState === Constants.hlsEvents.HLS_PLAYABLE ||
          isLive
            ? "Stop \nLive Stream"
            : hlsState === Constants.hlsEvents.HLS_STARTING
            ? "Starting \nLive Stream"
            : hlsState === Constants.hlsEvents.HLS_STOPPED
            ? "Start \nLive Stream"
            : hlsState === Constants.hlsEvents.HLS_STOPPING
            ? "Stopping \nLive Stream"
            : "Start \nLive Stream"
        }
        buttonText={
          hlsState === Constants.hlsEvents.HLS_STARTED ||
          hlsState === Constants.hlsEvents.HLS_PLAYABLE ||
          isLive
            ? "Stop Live"
            : hlsState === Constants.hlsEvents.HLS_STARTING
            ? "Starting Live"
            : hlsState === Constants.hlsEvents.HLS_STOPPED
            ? "Go Live"
            : hlsState === Constants.hlsEvents.HLS_STOPPING
            ? "Stopping Live"
            : "Go Live"
        }
        // isRequestProcessing={}
      />
    );

    // return (
    //   <OutlinedButton
    //     Icon={EndIcon}
    //     bgColor="bg-red-150"
    //     onClick={() => {
    //       leave();
    //       setIsMeetingLeft(true);
    //     }}
    //     tooltip="Leave Meeting"
    //   />
    // );
  };

  const ChatBTN = ({ isMobile, isTab }) => {
    return (
      <OutlinedButton
        Icon={ChatIcon}
        onClick={() => {
          setSideBarMode((s) =>
            s === sideBarModes.CHAT ? null : sideBarModes.CHAT
          );
        }}
        isFocused={sideBarMode === "CHAT"}
        // tooltip="View Chat"
      />
    );
  };

  const HLSBTN = ({ isMobile, isTab }) => {
    const isHls = useIsHls();

    const { isRequestProcessing } = useMemo(
      () => ({
        isRequestProcessing:
          hlsState === Constants.hlsEvents.HLS_STARTING ||
          hlsState === Constants.hlsEvents.HLS_STOPPING,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [hlsState]
    );

    const { type, priority, gridSize } = useMemo(
      () => ({
        type: "SPOTLIGHT",
        priority: "PIN",
        gridSize: "12",
      }),
      []
    );

    const typeRef = useRef(type);
    const priorityRef = useRef(priority);
    const gridSizeRef = useRef(gridSize);
    const isHlsRef = useRef(isHls);

    useEffect(() => {
      typeRef.current = type;
    }, [type]);

    useEffect(() => {
      priorityRef.current = priority;
    }, [priority]);

    useEffect(() => {
      gridSizeRef.current = gridSize;
    }, [gridSize]);

    useEffect(() => {
      isHlsRef.current = isHls;
    }, [isHls]);

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: liveHLS,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      height: 64,
      width: 170,
    };

    const _handleStartLiveStream = async () => {
      setHlsState("HLS_STARTING");
      const params = new URLSearchParams(window.location.search);
      const authToken = params.get("t");

      const response = await sendGraphql(
        `mutation StartBrowserBroadcast($slug: String) { startBrowserBroadcast(streamSlug: $slug) }`,
        authToken,
        { slug: stream.slug }
      );

      if (!response.data.data.startBrowserBroadcast) {
        alert("Unable to start live stream");
        return;
      }
      setHlsState("HLS_STARTED");
    };

    const _handleStopLiveStream = async () => {
      setHlsState("HLS_STOPPING");
      const params = new URLSearchParams(window.location.search);
      const authToken = params.get("t");

      const response = await sendGraphql(
        `mutation StopBrowserBroadcast($slug: String) { stopBrowserBroadcast(streamSlug: $slug) }`,
        authToken,
        { slug: stream.slug }
      );

      if (!response.data.data.stopBrowserBroadcast) {
        alert("Unable to stop live stream");
        return;
      }
      setHlsState("HLS_STOPPED");
    };

    const _handleClick = async () => {
      if (
        hlsState === Constants.hlsEvents.HLS_STARTED ||
        hlsState === Constants.hlsEvents.HLS_PLAYABLE
      ) {
        await _handleStopLiveStream();
      } else {
        await _handleStartLiveStream();
      }
    };

    return isMobile || isTab ? (
      <MobileIconButton
        onClick={_handleClick}
        tooltipTitle={
          hlsState === Constants.hlsEvents.HLS_STARTED ||
          hlsState === Constants.hlsEvents.HLS_PLAYABLE
            ? "Stop \nLive Stream"
            : hlsState === Constants.hlsEvents.HLS_STARTING
            ? "Starting \nLive Stream"
            : hlsState === Constants.hlsEvents.HLS_STOPPED
            ? "Start \nLive Stream"
            : hlsState === Constants.hlsEvents.HLS_STOPPING
            ? "Stopping \nLive Stream"
            : "Start \nLive Stream"
        }
        Icon={LiveIcon}
        buttonText={
          hlsState === Constants.hlsEvents.HLS_STARTED ||
          hlsState === Constants.hlsEvents.HLS_PLAYABLE
            ? "Stop Live"
            : hlsState === Constants.hlsEvents.HLS_STARTING
            ? "Starting Live"
            : hlsState === Constants.hlsEvents.HLS_STOPPED
            ? "Start Live"
            : hlsState === Constants.hlsEvents.HLS_STOPPING
            ? "Stopping Live"
            : "Start Live"
        }
        isFocused={isHls}
        lottieOption={isHls ? defaultOptions : null}
        isRequestProcessing={isRequestProcessing}
      />
    ) : null;
  };

  const tollTipEl = useRef();
  const isMobile = useIsMobile();
  const isTab = useIsTab();

  const [open, setOpen] = useState(false);

  const handleCloseFAB = () => {
    setOpen(false);
  };

  const BottomBarButtonTypes = useMemo(
    () => ({
      END_CALL: "END_CALL",
      CHAT: "CHAT",
      PARTICIPANTS: "PARTICIPANTS",
      SCREEN_SHARE: "SCREEN_SHARE",
      WEBCAM: "WEBCAM",
      MIC: "MIC",
      RAISE_HAND: "RAISE_HAND",
      RECORDING: "RECORDING",
      MEETING_ID_COPY: "MEETING_ID_COPY",
      HLS: "HLS",
      POLL: "POLL",
      REACTION: "REACTION",
      ECOMMERCE: "ECOMMERCE",
    }),
    []
  );

  const otherFeatures = [{ icon: BottomBarButtonTypes.CHAT }];

  if (meetingMode === Constants.modes.CONFERENCE) {
    otherFeatures.push({ icon: BottomBarButtonTypes.SCREEN_SHARE });
    otherFeatures.push({ icon: BottomBarButtonTypes.HLS });
  }

  return isMobile || isTab ? (
    <div
      className="flex items-center justify-center"
      style={{ height: bottomBarHeight }}
    >
      <LeaveBTN />
      {meetingMode === Constants.modes.CONFERENCE && (
        <>
          <MicBTN />
          <WebCamBTN />
        </>
      )}

      <ChatBTN isMobile={isMobile} isTab={isTab} />

      <Transition appear show={Boolean(open)} as={Fragment}>
        <Dialog
          as="div"
          className="relative"
          style={{ zIndex: 9999 }}
          onClose={handleCloseFAB}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="translate-y-full opacity-0 scale-95"
            enterTo="translate-y-0 opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="translate-y-0 opacity-100 scale-100"
            leaveTo="translate-y-full opacity-0 scale-95"
          >
            <div className="fixed inset-0 overflow-y-hidden">
              <div className="flex items-end justify-end h-full text-center">
                <Dialog.Panel className="w-screen overflow-hidden transition-all transform bg-gray-800 shadow-xl">
                  <div className="container grid py-6 bg-gray-800">
                    <div className="grid grid-cols-12 gap-2">
                      {otherFeatures.map(({ icon }) => {
                        return (
                          <div
                            key={`bottom-bar-btn-${icon}`}
                            className={`grid items-center justify-center ${
                              icon === BottomBarButtonTypes.MEETING_ID_COPY
                                ? "col-span-7 sm:col-span-5 md:col-span-3"
                                : "col-span-4 sm:col-span-3 md:col-span-2"
                            }`}
                          >
                            {icon === BottomBarButtonTypes.SCREEN_SHARE ? (
                              <ScreenShareBTN
                                isMobile={isMobile}
                                isTab={isTab}
                              />
                            ) : icon === BottomBarButtonTypes.CHAT ? (
                              <ChatBTN isMobile={isMobile} isTab={isTab} />
                            ) : icon === BottomBarButtonTypes.HLS ? (
                              <HLSBTN isMobile={isMobile} isTab={isTab} />
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Dialog.Panel>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </div>
  ) : (
    <div className="hidden px-2 pb-2 md:flex lg:px-2 xl:px-6">
      <div className="flex items-center justify-center flex-1" ref={tollTipEl}>
        {meetingMode === Constants.modes.CONFERENCE && (
          <ScreenShareBTN isMobile={isMobile} isTab={isTab} />
        )}
        {meetingMode === Constants.modes.CONFERENCE && (
          <>
            <MicBTN />
            <WebCamBTN />
          </>
        )}
        <LeaveBTN />
      </div>
      <div className="flex items-center justify-center">
        <ChatBTN isMobile={isMobile} isTab={isTab} />
      </div>
    </div>
  );
}
