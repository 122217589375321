import { PaperAirplaneIcon } from "@heroicons/react/solid";
import { useMeeting, usePubSub } from "@videosdk.live/react-sdk";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { formatAMPM, json_verify, nameTructed } from "../../utils/helper";
import { sendGraphql } from "../../utils/graphql";
import { convertUtcToTimezone } from "../../utils/helper";
import Pusher from "react-pusher";

const ChatMessage = ({ senderId, senderName, message, timestamp, me }) => {
  return (
    <div
      className={`flex ${me ? "justify-end" : "justify-start"} mt-4`}
      style={{
        maxWidth: "100%",
      }}
    >
      <div
        className={`flex ${
          me ? "items-end" : "items-start"
        } flex-col py-1 px-2 rounded-md bg-gray-700`}
      >
        <p style={{ color: "#ffffff80" }}>
          {me ? "You" : nameTructed(senderName, 29)}
        </p>
        <div>
          <p className="inline-block text-right text-white break-words whitespace-pre-wrap">
            {message}
          </p>
        </div>
        {/* <div className="mt-1">
          <p className="text-xs italic" style={{ color: "#ffffff80" }}>
            {formatAMPM(new Date(timestamp))}
          </p>
        </div> */}
      </div>
    </div>
  );
};

const ChatInput = ({ inputHeight, setMessages, stream }) => {
  const [message, setMessage] = useState("");
  // const { publish } = usePubSub("CHAT");
  const input = useRef();

  const publish = useCallback(
    async (messageText) => {
      setMessages((prev) => [
        ...prev,
        {
          id: Math.random().toString(36).substr(2, 9),
          senderId: "local",
          senderName: "You",
          message: messageText,
          timestamp: new Date(convertUtcToTimezone(new Date())),
          me: true,
        },
      ]);

      const params = new URLSearchParams(window.location.search);
      const authToken = params.get("t");

      const response = await sendGraphql(
        `mutation sendStreamMessage($slug: String!, $message: String!) {
        sendStreamMessage(streamSlug: $slug, message: $message) {
          id
          fromUserId
          fromUser {
            slug
            id
            avatar
            name
            bio
            location
            facebookUsername
            instagramUsername
            twitterUsername
            channel {
              location
            }
          }
          streamId
          message
        }
      }`,
        authToken,
        { slug: stream.slug, message: messageText }
      );

      if (!response.data.data.sendStreamMessage) {
        alert("Unable to send stream message");
        return;
      }
    },
    [setMessages, stream.slug]
  );

  return (
    <div
      className="flex items-center w-full px-2"
      style={{ height: inputHeight }}
    >
      <div className="relative  w-full">
        <span className="absolute inset-y-0 right-0 flex mr-2 rotate-90 ">
          <button
            disabled={message.length < 2}
            type="submit"
            className="p-1 focus:outline-none focus:shadow-outline"
            onClick={() => {
              const messageText = message.trim();
              if (messageText.length > 0) {
                // publish(messageText, { persist: true });
                publish(messageText);
                setTimeout(() => {
                  setMessage("");
                }, 100);
                input.current?.focus();
              }
            }}
          >
            <PaperAirplaneIcon
              className={`w-6 h-6 ${
                message.length < 2 ? "text-gray-500 " : "text-white"
              }`}
            />
          </button>
        </span>
        <input
          type="text"
          className="w-full py-4 pl-2 pr-10 text-base text-white border border-gray-400 rounded bg-gray-750 focus:outline-none"
          placeholder="Write your message"
          autoComplete="off"
          ref={input}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              const messageText = message.trim();

              if (messageText.length > 0) {
                publish(messageText);

                setTimeout(() => {
                  setMessage("");
                }, 100);
                input.current?.focus();
              }
            }
          }}
        />
      </div>
    </div>
  );
};

const ChatMessages = ({ listHeight, stream, messages, setMessages }) => {
  const listRef = useRef();
  // const { messages } = usePubSub("CHAT");

  const getMessagesQuery = useCallback(async () => {
    const params = new URLSearchParams(window.location.search);
    const authToken = params.get("t");

    const response = await sendGraphql(
      `query streamMessages($slug: String!, $limit: Int, $offset: Int) {
        streamMessages(slug: $slug, limit: $limit, offset: $offset) {
          id
          message
          fromUserId
          fromUser {
            slug
            name
            location
            avatar
          }
          streamId
        }
      }`,
      authToken,
      { slug: stream.slug, limit: 100, offset: 0 }
    );

    if (!response.data.data.streamMessages) {
      alert("Unable to get stream messages");
      return;
    }

    const response2 = await sendGraphql(
      `query me {
        me {
          user {
            slug
          }
        }
      }`,
      authToken,
      { slug: stream.slug, limit: 100, offset: 0 }
    );

    if (!response2.data.data.me) {
      alert("Unable to get stream messages");
      return;
    }

    setMessages(
      response.data.data.streamMessages
        .map((msg) => {
          const { id, message, fromUser, createdAt } = msg;
          const { slug, name } = fromUser;
          return {
            id,
            senderId: slug,
            senderName:
              slug === response2.data.data.me.user.slug ? "You" : name,
            message,
            timestamp: new Date(convertUtcToTimezone(createdAt)),
            me: slug === response2.data.data.me.user.slug,
          };
        })
        .reverse()
    );
  }, [setMessages, stream.slug]);

  useEffect(() => {
    getMessagesQuery();
  }, [getMessagesQuery]);

  const scrollToBottom = (data) => {
    if (!data) {
      if (listRef.current) {
        listRef.current.scrollTop = listRef.current.scrollHeight;
      }
    } else {
      const { text } = data;

      if (json_verify(text)) {
        const { type } = JSON.parse(text);
        if (type === "CHAT") {
          if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
          }
        }
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return messages ? (
    <div ref={listRef} style={{ overflowY: "scroll", height: listHeight }}>
      <div className="p-4">
        {messages.map((msg, i) => {
          return <ChatMessage key={`chat_item_${i}`} {...msg} />;
        })}
      </div>
    </div>
  ) : (
    <p>No messages</p>
  );
};

export function ChatPanel({ panelHeight, stream }) {
  const inputHeight = 72;
  const listHeight = panelHeight - inputHeight;

  const [messages, setMessages] = useState([]);

  const handleOnSocketUpdate = (data) => {
    console.log("handleOnSocketUpdate", data);
    setMessages((prev) => [
      ...prev,
      {
        id: "id",
        senderId: data.user.id,
        senderName: data.user.name,
        message: data.message,
        timestamp: new Date(convertUtcToTimezone(data.created_at)),
      },
    ]);
  };

  return (
    <div>
      <ChatMessages
        messages={messages}
        setMessages={setMessages}
        stream={stream}
        listHeight={listHeight}
      />
      <ChatInput
        stream={stream}
        setMessages={setMessages}
        inputHeight={inputHeight}
      />
      {Pusher.pusherClient && stream?.slug && (
        <Pusher
          channel={`stream_${stream.slug}`}
          event="new-stream-message"
          onUpdate={handleOnSocketUpdate}
        />
      )}
    </div>
  );
}
