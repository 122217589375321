import React from "react";

export function MeetingDetailsScreen({ onClickJoin, disabled, busy }) {
  return (
    <div
      className={`flex flex-1 flex-col justify-center w-full md:p-[6px] sm:p-1 p-1.5`}
    >
      <button
        className={`w-full ${
          disabled ? "bg-gray-650" : "bg-purple-350"
        }   text-white px-2 py-3 rounded-xl mt-5`}
        onClick={() => {
          onClickJoin();
        }}
        disabled={disabled}
      >
        {busy ? "Joining Studio.." : "Join Studio"}
      </button>
    </div>
  );
}
